// import logo from './logo.svg';
// import './App.css';
import { Box, Grommet, Image, Anchor, Paragraph, Text } from 'grommet';

import React from 'react';

const theme = {
  global: {
    font: {family: 'Inconsolata',
           size: '18px',
           height: '20px'
      }
  }
};



const FooterSection = () => (
  <>
  <Box direction="column">
    <Box
      tag='footer'
      direction='row'
      justify='center'
      pad='small'
      gap='small'

      // flex={true}
      background='black'>

      <Anchor 
              href="https://benthiclabel.bandcamp.com"
              target="_blank"
              color="white"
              size='small'
              >bandcamp</Anchor> | 
              
      <Anchor href="https://www.youtube.com/channel/UCBzorg5PGSQgnrXmtO_AZYA"
              target="_blank"
              color="white"
              size='small'
              >youtube</Anchor> | 
              
      <Anchor href="https://soundcloud.com/oathy"
              target="_blank"
              color="white"
              size='small'
              >soundcloud</Anchor>
      </Box>

      <Box justify='center'direction='row'>
        <Text> contact@oathcreations.com </Text>   
      </Box>
  </Box>
  
  </>
  
  );
  

function App() {
  return (
    <Grommet theme={theme} full>
       
     <Box justify="evenly" align="between" background="black" fill>
     <Box direction="column" align="center" background="black" fill>

      <Image src="./benthic_logo.png" 
              width="100px" 
              height="100px"
              margin="50px">
      </Image>

      <Paragraph justify="center">
      Millennial Lowlife by Acronym is out
      </Paragraph>

      <Paragraph>

      <Anchor 
            href="https://benthiclabel.bandcamp.com/album/millennial-lowlife"
            target="_blank"
            color="white">
          [BENTXX2]  Acronym -- Millennial Lowlife EP
          </Anchor> <br></br>
        
          <Anchor 
            href="https://benthiclabel.bandcamp.com/album/assurance-ep"
            target="_blank"
            color="white">
          [BENTXX1] Lucid Void -- Assurance EP 
          </Anchor> <br></br>
          

  
      </Paragraph>    
     </Box>
        
     <FooterSection/>
     </Box>


     
    </Grommet>
  );
}

export default App;
